import { ReactNode } from 'react'

export enum ColumnSizeTypes {
  Half = 'half',
  Full = 'full'
}

export type StandardScreenProps = {
  children?: ReactNode
  onboarding?: boolean
  hasSingleColumn?: boolean | (() => boolean)
  columnSize?: () => ColumnSizeTypes | false
  publicScreen?: boolean
}
