import { DefaultApi, ApiConfigs, OnRequestFail, ApiConfig } from '@commonstock/client/src/constants'
import createWebCache, { ClientCacheKey } from '@commonstock/client/src/cache/createWebCache'

import config from '../config'
import { authenticator } from './authenticator'
import createClient from '@commonstock/client/src/createClient'
import { Apis } from '@commonstock/common/src/types/apis'
// import sleep from '@commonstock/common/src/utils/sleep'
// import { captureException } from '../dev/sentry'

// const FAILED_TO_FETCH_ERROR_MESSAGE = 'Failed to fetch'

const headers = { 'Content-Type': 'application/json' }
const defaultOnRequestFail: OnRequestFail = (resource, _client, err, request) => {
  // @NOTE: Commenting this out, as this quickly consume all our event quota on sentry
  // @TODO: find a way to send this errors to a different sentry project
  // const errorMessage = err?.message || resource.fail?.message || ''
  // let formattedSentryError = new Error(`api-client(${resource.methodOptions.key}): ${errorMessage}`)
  // if (errorMessage.includes('token is expired by')) {
  //   formattedSentryError = new Error(`expired-token: ${errorMessage}`)
  // }
  // const fingerprint = [
  //   err?.message === FAILED_TO_FETCH_ERROR_MESSAGE ? FAILED_TO_FETCH_ERROR_MESSAGE : resource.methodOptions.key
  // ]
  // captureException(formattedSentryError, { resource, client, err, request }, fingerprint)
  console.error('## api err', resource, err, request)
}

// @NOTE: Use this method to test slowed down requests without throttling browser netword
// const timeoutHandleResponse = async (response: Response) => {
//   if (!response.url.includes('get-my-profile')) await sleep(5000)
//   let isJson = (response.headers.get('content-type')?.indexOf('application/json') ?? -1) >= 0
//   return isJson ? response.json() : response.text()
// }

type ApiBits = Array<[string, string, Partial<ApiConfig>]>
let apiBits: ApiBits = [
  [DefaultApi, config.apiPaths[Apis.Gateway], {}],
  // We need to send with no content-type, because Media service takes it as media type
  [Apis.Media, config.apiPaths[Apis.Gateway], { headers: undefined }],
  [Apis.Auth, config.apiPaths[Apis.Gateway], { authenticator: undefined }],
  // non env specific
  [Apis.MockApi, config.apiMockApi, {}],
  [Apis.Giphy, config.apiGiphy, { authenticator: undefined }],
  [Apis.Auth0, config.apiAuth0, { authenticator: undefined }]
]
let apis: ApiConfigs = {}
apiBits.forEach(([key, basePath, extraConfig]) => {
  apis[key] = {
    headers,
    authenticator,
    onRequestFail: defaultOnRequestFail,
    basePath,
    debug: config.debugClient,
    // handleResponse: timeoutHandleResponse,
    ...extraConfig
  }
})

if (config.disableClientCache) localStorage.removeItem(ClientCacheKey)
let client = createClient(apis, createWebCache())

export default client
