/*
  Wrapper for localStorage that
  - Promisify's the api
  - Throws a predictable error if storage fails or is not available
  */

let ls = (global.window && global.window.localStorage) || { getItem: () => {} }
type StringOrNull = string | null
const LocalStorage = {
  getItem: (key: string): Promise<StringOrNull> => {
    return new Promise(resolve => {
      resolve(ls.getItem(key))
    })
  },
  setItem: (key: string, item: string): Promise<void> => {
    return new Promise(resolve => {
      resolve(ls.setItem(key, item))
    })
  },
  removeItem: (key: string): Promise<void> => {
    return new Promise(resolve => {
      resolve(ls.removeItem(key))
    })
  }
}

export { LocalStorage }
