import { ClientCache } from './ClientCache'
import { LocalStorage } from './LocalStorage'
import { createResourceCountStrategy } from './resourceCountStrategy'

export const ClientCacheKey = 'client-cache'
export default function createWebCache(maxCache = 500) {
  let cacheStrategy = createResourceCountStrategy(maxCache)
  let cache = new ClientCache(ClientCacheKey, LocalStorage)
  cacheStrategy(cache)
  return cache
}
